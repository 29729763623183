<template>
  <div class="row">
    <div class="col-12">
      <ibox :title="'Rejestrcje'">
        <template slot="content" slot-scope="{ setLoading }">
          <Registration @loading="setLoading" />
        </template>
      </ibox>
    </div>
  </div>
</template>

<script>
import Ibox from "@/components/base/Ibox.vue";
import Registration from "@/components/Statistics/Registration";

export default {
  name: "RegistrationStatistics",
  components: {
    Ibox,
    Registration
  }
};
</script>

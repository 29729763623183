<template>
  <div>
    <form autocomplete="off" @submit.prevent="handleSubmit">
      <input
        autocomplete="off"
        name="hidden"
        type="text"
        style="display: none"
      />
      <div
        class="row"
        :class="{ 'has-error': errors.has('radioDefaultOrClub') }"
      >
        <div class="col-sm-3">
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="inlineRadioDefaultOrClub"
              id="radioDefault"
              v-model="radioDefaultOrClub"
              value="default"
            />
            <label class="form-check-label" for="radioDefault"
              >Wszystkie rejestracje</label
            >
          </div>
          <div class="form-check form-check-inline" v-if="lang === 'pl'">
            <input
              class="form-check-input"
              type="radio"
              name="inlineRadioDefaultOrClub"
              id="radioClub"
              v-model="radioDefaultOrClub"
              value="club"
            />
            <label class="form-check-label" for="radioClub">Klub NN</label>
          </div>
        </div>
        <div class="col-4 form-check-inline">
          <div class="row">
            <div
              class="form-group col-sm-4"
              :class="{ 'has-error': errors.has('dateStart') }"
            >
              <label>
                {{ $t("start_date") }}
              </label>
              <date-picker
                class="NeoDataPicker"
                v-model="dates.dateStart"
                :config="optionsMonth"
                name="dateStart"
                autocomplete="off"
                v-validate="'required'"
              />
              <small v-show="errors.has('dateStart')" class="text-danger">
                {{ $t("field_required") }}
              </small>
            </div>
            <div
              class="form-group col-sm-4"
              :class="{ 'has-error': errors.has('dateEnd') }"
            >
              <label>
                {{ $t("end_date") }}
              </label>
              <date-picker
                class="NeoDataPicker"
                v-model="dates.dateEnd"
                :config="optionsMonth"
                name="dateEnd"
                autocomplete="off"
                v-validate="'required'"
              />
              <small v-show="errors.has('dateEnd')" class="text-danger">
                {{ $t("field_required") }}
              </small>
            </div>
          </div>
        </div>

        <div class="col-8">
          <div v-if="radioDefaultOrClub === 'default'" class="form-group">
            <div class="form-check form-check-inline">
              <button
                type="button"
                class="btn btn-outline btn-primary"
                @click="handleSubmit"
              >
                {{ $t("download_excel") }}
              </button>
            </div>
          </div>
          <div v-if="radioDefaultOrClub === 'club'" class="form-group">
            <div class="form-check form-check-inline">
              <button
                type="button"
                class="btn btn-outline btn-primary"
                @click="handleSubmit"
              >
                {{ $t("download_excel") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "Registration",
  data() {
    return {
      dates: {
        dateStart: null,
        dateEnd: null
      },
      radioDefaultOrClub: 0,
      lang: this.$route.params.shop_lang,
      filename: "rejestracje"
    };
  },
  computed: {
    urlRegistrations() {
      return `${this.$route.params.shop_name}/${this.$route.params.shop_lang}/exports/registrations`;
    },
    urlClubRegistrations() {
      return `${this.$route.params.shop_name}/${this.$route.params.shop_lang}/exports/club-registrations`;
    }
  },
  created() {
    this.dates.dateStart = this.$moment()
      .subtract(1, "year")
      .format("YYYY-MM");
    this.dates.dateEnd = this.$moment().format("YYYY-MM");
    this.$emit("loading", false);
  },
  methods: {
    handleSubmit() {
      this.$emit("loading", true);
      this.$http
        .customRequest({
          url:
            this.radioDefaultOrClub == "default"
              ? `${this.urlRegistrations}?dateStart=${this.dates.dateStart}&dateEnd=${this.dates.dateEnd}`
              : `${this.urlClubRegistrations}?dateStart=${this.dates.dateStart}&dateEnd=${this.dates.dateEnd}`,
          method: "GET",
          data: this.dates,
          responseType: "blob"
        })
        .then(response => {
          this.$func.downloadExcelFile(response, this.filename);
          this.$emit("loading", false);
        })
        .catch(err => {
          this.$toastr.error(err);
          console.log(err.response);
          this.$emit("loading", false);
        });
    }
  }
};
</script>
